import ProjectCard from "./projectCard";


import Content_template from "./content_template";
import Background from '.././assets/temp-back.webp';

// import Content_template from "./content_template";
import ComingSoonIcon from '.././assets/Comingsoon.png';

import Content_sistafam from "./content_sistafam";
import SistafamLogo from '.././assets/icon_sistafam.jpg';

import Content_mazeCo from "./content_MazeApp";
import MazeIcon from '.././assets/icon_mazeApp.jpg';

import Content_ExperienceDesign from "./content_experienceDesign.jsx";
import ExperienceDesignIcon from '.././assets/ExperienceDesign.png';

import Content_UIApp from "./content_UIProject";
import UIappIcon from '.././assets/icon_UIapp.jpg';

import Conetent_Portfolio from "./content_portfolio";
import ReactIcon from '.././assets/reactIcon.webp';

import Content_AGILEMTG from "./content_MTGAGILE";
import AgileIcon from '.././assets/agileIcon.png';

import Content_sonnet18Zine from "./content_Sonnet18zine";
import ZineIcon from '.././assets/Zine/Zine_redone_1.jpg';

function Page_projects(props) {

    return(
        <div className="contentBox">
            <div className="resumeGrid">

            <h1 className="portfolio_heading">Post-Grad Projects</h1>
                <div className="projectsGridRow">
                    <ProjectCard 
                            updatepage = {props.updatepage}
                            title="React and Me" 
                            subtitle="The 'what's what' behind this web portfolio"
                            backgroundImage = {<div className="photoContainer"><img src={ReactIcon} className='projectCard_ImageStyle' alt='Loading'></img></div>}
                            pagelink={<Conetent_Portfolio/>}
                    />


                </div>
                <h1 className="portfolio_heading">University Projects</h1>
                <div className="projectsGridRow">
                    <ProjectCard 
                        updatepage = {props.updatepage}
                        title="Sistafam" 
                        subtitle="Female focused social media network"
                        backgroundImage = {<div className="photoContainer"><img src={SistafamLogo} className='projectCard_ImageStyle' alt='Loading'></img></div>}
                        pagelink={<Content_sistafam />}

                    />
                    <ProjectCard 
                        updatepage = {props.updatepage}
                        title="Maze App" 
                        subtitle="A maze editing tool for aspiring newspapers"
                        backgroundImage = {<div className="photoContainer"><img src={MazeIcon} className='projectCard_ImageStyle' alt='Loading'></img></div>}
                        pagelink={<Content_mazeCo/>}
                    />

                    <ProjectCard 
                        updatepage = {props.updatepage}
                        title="Console UI App" 
                        subtitle="My first steps into class design"
                        backgroundImage = {<div className="photoContainer"><img src={UIappIcon} className='projectCard_ImageStyle' alt='Loading'></img></div>}
                        pagelink={<Content_UIApp/>}
                    />
                  
                  <ProjectCard 
                        updatepage = {props.updatepage}
                        title="Experience Design" 
                        subtitle="The theory behind restaurant design"
                        backgroundImage = {<div className="photoContainer"><img src={ExperienceDesignIcon} className='projectCard_ImageStyle' alt='Loading'></img></div>}
                        pagelink={<Content_ExperienceDesign/>}
                    />
                    <ProjectCard 
                        updatepage = {props.updatepage}
                        title="Sonnet 18" 
                        subtitle="An art project of the Australian desert"
                        backgroundImage = {<div className="photoContainer"><img src={ZineIcon} className='projectCard_ImageStyle' alt='Loading'></img></div>}
                        pagelink={<Content_sonnet18Zine/>}
                    />
                </div>


            </div>
        </div>
    );
}

export  default Page_projects;