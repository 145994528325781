


function Page_resume() {


    return(
        <div className="contentBox">
            
            
            <h1 className="portfolio_heading">Qualifications</h1>
            <ul>
                <li className="listColour"><text className="portfolio_text">Introduction to React | Coursera 2023</text></li>
                <li className="listColour"><text className="portfolio_text">Bachelor of IT - Computer science major, Graphic design and engineering minor | QUT 2022</text></li>
                <li className="listColour"><text className="portfolio_text">Certificate III Media | Tafe 2011</text></li>
            </ul>
               
                
            <h1 className="portfolio_heading">Skills</h1>
            <div className="resumeGrid">
                <div className="gridRow portfolio_text">
                    <text className=" skillName portfolio_text">Java</text>
                    <text className="portfolio_text" >I used java to create the back-end of the MazeApp maze editing tool that implemented a graph and graph traversal techniques to find the shortest path between two points.  This tool also needed to maintain the state of the maze including walls, start/finish and any art that was placed within the maze.</text>

                    <div className="lineBreak"></div>

                    <text className=" skillName portfolio_text">C#</text>
                    <text className="portfolio_text" >I have both practical experience and theoretical understanding of using C# and the .NET framework.  My theoretical understanding foundation was formed when creating the  Console UI App and my practical experience was gained during several smaller university projects. </text>

                    <div className="lineBreak"></div>

                    <text className=" skillName portfolio_text">Flutter</text>
                    <text className="portfolio_text" >Through the development of the Sistafam social media app I acquired practical experience in using the flutter frameowrk for mobile and web development. This project bought additional value to the company by creating a base framework from the app to be completed by another team in the next year before the app officially releases.</text>

                    <div className="lineBreak"></div>

                    <text className=" skillName portfolio_text">React</text>
                    <text className="portfolio_text">To gain experience with react, I completed an online course through Coursera and then proceeded use that knowledge to build this web portfolio.</text>

                    <div className="lineBreak"></div>

                    <text className=" skillName portfolio_text">Databases</text>
                    <text className="portfolio_text">During  the development of the mazeApp and several introduction subjects I gained practical experience using HeidiSQL and Mongo DB to design, make and manage MySQL relational databases by reducing data sets to third normal form and implementing the solution we designed.  My time creating the Sistafam Social media app had me using a non-relational database built into firebase.</text>

                    <div className="lineBreak"></div>
                    
                    <text className=" skillName portfolio_text">Git</text>
                    <text className="portfolio_text" >I used Git based tools to manage code within teams and in an attempt to keep to best practices, I routinely use side branches to develop updates before merging them with the main branch.</text>

                    <div className="lineBreak"></div>

                    <text className=" skillName portfolio_text">Unit Testing</text>
                    <text className="portfolio_text" >I have a strong understanding of how classes are structured and interact and can leverage this knowledge to build effective test cases for a variety of situations.  THe tools I have used in the past are JUnit for the MazeApp, the Flutter_Test suite for the Sistafam Social media app and a modified version of the C# testing tool 'xUnit' provided by QUT.</text>

                    <div className="lineBreak"></div>

                    <text className=" skillName portfolio_text">Microsoft office </text>
                    <text className="portfolio_text">Along side the basic word and powerpoint skills that are demonstrated in the projects explanation video's, I have used excel to create a budget management tool and manage and edit data for use in a database.</text>

                    <div className="lineBreak"></div>
                    
                    <text className=" skillName portfolio_text">Public speaking</text>
                    <text className="portfolio_text">Through my years working as a retail assistant and working on group projects, I developed a solid understandig of how to effectively communicate ideas, thoughts and knowledge with other people in a way that would be received well by them.  Additionally, during the end of year QUT capstone expo I ran the booth that was displaying the Sistafam app and spent the day answering questions to general members of the public, technical professionals and other QUT capstone teams.</text>
                </div>

                {/* 
                    <text className="skillName">Another example </text>
                    <text >Example</text>
                */}

 
            </div>
            <h1 className="portfolio_heading">Employment History</h1>
            <ul>
                <li className="listColour"><text className="portfolio_text">Night Fill | Coles Supermarket | May 2023 - Present </text></li>
                <li className="listColour"><text className="portfolio_text">Retail Clerk | Rankins on the  Mall | Nov 2015 - May 2018</text> </li>
                <li className="listColour"><text className="portfolio_text">Retail Clerk | The Friendly Grocer | Nov 2013 - July 2015</text> </li>
                <li className="listColour"><text className="portfolio_text">Game Making Trainee | Valhallah Studios | June 2011 - Dec 2011</text></li>
            </ul>
                

                


        </div>
    );
}

export  default Page_resume;