import React from "react";
import Page_resume from "./page_resume";
import Page_about from "./page_about";
import Page_projects from "./page_projects";



function sidebar(props) {


    return(
        <div className="sidebar_button_container">
            <button 
                onClick={() => props.updatepage(<Page_about />)} 
                className="sidebar_buttons">
                    <text 
                        className="sidebar_button_text">
                        About
                    </text>
                </button>
            <button 
                onClick={() => props.updatepage(<Page_resume />)} 
                className="sidebar_buttons">
                    <text 
                        className="sidebar_button_text">
                        Qualifications
                    </text>
                </button>
            <button 
                onClick={() => props.updatepage(<Page_projects updatepage = {props.updatepage} />)} 
                className="sidebar_buttons">
                    <text 
                        className="sidebar_button_text">
                        Projects
                    </text>
                </button>

        </div>
    );
}

export  default sidebar;