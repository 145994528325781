import React from "react";
import { useState } from "react";
import Sidebar from "./sidebar";
import Content from "./contents";
// import ContentTemplate from "./content_template";
import AboutPage from "./page_about";


function Layout() {

    
    const [page, setPage] = useState(<AboutPage />);

    function updatePage(newPage) {
        setPage(newPage)
    }

    return(
        <div className="grid_Container">
            <div className="grid_layout">
                <Sidebar updatepage = {updatePage} />
                <Content p={page} />
            </div>
        </div>
    );
}

export  default Layout;