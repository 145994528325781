import React from "react";
import './component_stylesheet.css';


function header() {

    return(
        <div className="row header">
            
            <div className="nameStyle">
                <text>James Moss</text>
            </div>
            
        </div>
    );
}

export  default header;