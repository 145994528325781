import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';

// Sistafam:  https://www.youtube.com/embed/FScfjs8yjkQ?si=9fG1qIdLUnjtxCNi
// UI Project: https://www.youtube.com/embed/GPxwV93Q6n8?si=g-pUCEqXeI5FfyLe
// Experience Design: https://www.youtube.com/embed/juDFK2LI62A?si=16IER2BSIq5Gd3cr
// MazeCo presentation: https://www.youtube.com/embed/J3aMdmDOgdA?si=Be6T5qtaKeRXvfs0


import Exp_ironmanAnalysis from '.././assets/experienceDesign_imageanalysis.jpg';
import Exp_MTGAnalysis from '.././assets/experienceDesign_imageanalysisMTG.jpg';


function Content_experienceDesign() {

    return(
        <div className="contentBox">
            <h1 className="project_Title">Experience Design</h1>
            <h2 className="portfolio_heading">Project Overview</h2>
            <text className="portfolio_text"> As part of my graphic design minor, we were tasked to create teams of 4-6, choose a visual design theory that we had been studying during the semester and then use that theory to analyse the design of a common everyday location of our choosing. For our analysis, we chose to dissect burger restaurants, each choosing one to visit and take a series of photos of for further analysis. The analysis was done through the lens of ‘Tiger’s Theory of pleasure design’ and is built on the idea that everything we experience appeals to one of four pleasures: <br></br><br></br>

            <ul>
                <li className='listColour'><text className="portfolio_text">Physio pleasure – The pleasure associated with tactile sensations</text></li>
                <li className='listColour'><text className="portfolio_text">Socio-pleasure – the pleasure associated with experiencing something with others</text></li>
                <li className='listColour'><text className="portfolio_text">Psycho-Pleasure – The pleasure associated with intellectual stimulation</text></li>
                <li className='listColour'><text className="portfolio_text">Ideo-pleasure – The pleasure associated with the meaning that the individual has attached to the item itself</text></li>
            </ul>

            <br></br>
            Each design element and choice fits into either one or multiple of these categories. Through this theory we worked together to form a deeper understanding of environmental design and how these restaurants use their design to influence the behaviour of the customer. <br></br><br></br>
            </text>
            
            <Container>
            <div class="ratio ratio-16x9">
                <iframe 
                    src="https://www.youtube.com/embed/juDFK2LI62A?si=16IER2BSIq5Gd3cr" 
                    title="YouTube video" 
                    allowFullScreen
                    className='videoSize'
                    ></iframe>
            </div>
            </Container>

            <h2 className="portfolio_heading">My Contribution</h2>
            <text className="portfolio_text"> During this project it was required that we all participate fairly evenly during every stage of the project.  Despite this however, our natural team balance meant that those who were more research inclined went into more depth with their research and I delved deeper into the analysis and understanding of the data that they had collected. 
            <br></br><br></br>
            After understanding the data, my process for the analysis was to start by figuring out how each data point was or could be associated with another. I would read each piece of the research and assign general overview tags to each to make categorising them significantly easier. Once the tags were in place, I organised them based on similarity, getting my team to weigh in when I was unsure of how similar/dissimilar we should consider particular elements. Through this process we collectively identified four primary themes within the restaurant design, that being the restaurants’ theme, the visual communication, the information management and interior design. These four categorical umbrellas defined the remainder of our project as we moved forward. Once they were in place, it was significantly easier to recognise the links and associations between the data and draw logical conclusions based on them. 
            <br></br><br></br>
            </text>
            
            <h2 className="portfolio_heading">Detail Oriented</h2>
            <text className="portfolio_text"> Below is a sample of some work from early in the semester that outlines my analysis of art. In order to succeed in this subject and achieve the highest marks, we needed to be very detail oriented and be able to correctly identify the flow of the image as well as major design elements. <br></br><br></br></text>
            
            <div className='projects_Experiencepage_GridRow'>
                <div>
                    <div className='photoContainer'>
                                <img src={Exp_ironmanAnalysis}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
                <div>
                    <div className='photoContainer'>
                                <img src={Exp_MTGAnalysis}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export  default Content_experienceDesign;


