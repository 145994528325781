import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';

// Sistafam:  https://www.youtube.com/embed/FScfjs8yjkQ?si=9fG1qIdLUnjtxCNi
// UI Project: https://www.youtube.com/embed/GPxwV93Q6n8?si=g-pUCEqXeI5FfyLe
// Experience Design: https://www.youtube.com/embed/juDFK2LI62A?si=16IER2BSIq5Gd3cr
// MazeCo presentation: https://www.youtube.com/embed/J3aMdmDOgdA?si=Be6T5qtaKeRXvfs0
// Portfolio Video presentation: https://www.youtube.com/embed/WbciO-UmApM?si=fz4e_mqElGq69xBa

import Hierarchy from '.././assets/WebpageHierarchy.JPG';




function Content_portfolio() {

    return(
        <div className="contentBox">
            <h1 className="project_Title">Portfolio V3.0 - Now made in React!</h1>
            <h2 className="portfolio_heading">Project Overview</h2>
            <text className="portfolio_text">With a portfolio like this my goal is - obviously – to get hired.  In order to give myself the  best chance at doing  so I scoured as many job listings as I could to determine what skills are desirable for the industry at large.  While there were several options, I decided to begin by learning React as it is both a highly desirable skill to have but also so that I could upgrade the overall appearance of my portfolio.  The previous two iterations were made using the Flutter framework, which while fantastic for mobile development, doesn’t lend itself well to web development.  My pathway for learning React so far has been to do a course through Coursera and then funnel what I learnt into building  this portfolio.
            <br></br><br></br>
            </text>
            
            <Container>
            <div class="ratio ratio-16x9">
                <iframe 
                    src="https://www.youtube.com/embed/WbciO-UmApM?si=fz4e_mqElGq69xBa" 
                    title="YouTube video" 
                    allowFullScreen
                    className='videoSize'
                    ></iframe>
            </div>
            </Container>

            <h2 className="portfolio_heading">Portfolio Version 1 & 2</h2>
            <text className="portfolio_text">The earlier two portfolio’s I created were made using a framework that was not designed for web app development. The framework used was Google’s Flutter framework which uses similar principals to react in how it handles the DOM and virtual DOM and has a very similar component structure. Those versions of the portfolio served as an effective proof of concept and were always intended to be replaced by this current iteration. 
            <br></br><br></br>
            </text>

            <h2 className="portfolio_heading">The Structure</h2>
            <text className="portfolio_text">The structure of the web app utilises standard and custom react components to be highly modular and compartmentalise functionality. Within the app itself there are three primary components being the header, the layout and footer. The header and footer are both static and mostly serve to set clear boundaries as to where the page starts and ends while also conveying a small amount of information. The bulk of the page is occupied by a grid layout that houses the majority of the functionality as the remainder of the pages are static. 
            <br></br><br></br>
            
            </text>

            <div className='projects_page_GridRow'>
                <div>
                    <div className='photoContainer'>
                                <img src={Hierarchy}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
            </div>

            <text className="portfolio_text"> 
            <br></br>
            The ‘Layout’ component contains just one state element that is used to determine which page is currently displayed. This state is then passed down to the page display container and the contents are shown appropriately. The UpdatePage function is a callback function that gets passed down to the sidebar to enable navigation as it passes the desired page from itself (the child) back up to ‘Layout’ (The parent). Given that react has listeners on each piece of state, any time a button is clicked the, the updatePage function is called, updating the current page state and then all the elements on the subsequent branch will be updated.
            </text>
            
        </div>
    );
}

export  default Content_portfolio;


