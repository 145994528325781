import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';

// Sistafam:  https://www.youtube.com/embed/FScfjs8yjkQ?si=9fG1qIdLUnjtxCNi
// UI Project: https://www.youtube.com/embed/GPxwV93Q6n8?si=g-pUCEqXeI5FfyLe
// Experience Design: https://www.youtube.com/embed/juDFK2LI62A?si=16IER2BSIq5Gd3cr
// MazeCo presentation: https://www.youtube.com/embed/J3aMdmDOgdA?si=Be6T5qtaKeRXvfs0





function content_template() {

    return(
        <div className="contentBox">
            <h1 className="project_Title">QUT UI Project</h1>
            <h2 className="portfolio_heading">Project Overview</h2>
            <text className="portfolio_text"> This project was my first real introduction to object-oriented programming and through creating this app I developed a deeper understanding of how to make modular code that could be readily reused. The app that we were tasked with creating is that of a property trading app which would allow people to register an account, login, advertise their property, search for and bid on other properties and list different property types. The project was set up to get us use to both designing and using classes and as such did not have need of any long-term data storage. 
             <br></br><br></br>

            The video below shows my approach to class design based on user requirements that I still use to this day and a demonstration of the app in use. <br></br><br></br>
            </text>
            
            <Container>
            <div class="ratio ratio-16x9">
                <iframe 
                    src="https://www.youtube.com/embed/GPxwV93Q6n8?si=g-pUCEqXeI5FfyLe" 
                    title="YouTube video" 
                    allowFullScreen
                    className='videoSize'
                    ></iframe>
            </div>
            </Container>

            <h2 className="portfolio_heading">Class Design</h2>
            <text className="portfolio_text"> The purpose of object-oriented programming is to increase the modularity of code and reduce the amount of duplicate code with similar functions. In an ideal scenario, each segment of code would have an exact purpose and be reusable whenever it was needed throughout the app and could even be transferred onto another project. By using class design principals and utilising inheritance, the scale of code can be minimised.
            <br></br><br></br>
            To assist with the creation of more complex software structures, there are several types of classes, they are; <br></br><br></br>

            <ul>
                <li className='listColour'><text className="portfolio_text">Interface – A class that does not contain any functionality of its own, but declares functions that must exist for any class inheriting from it. It acts as a promise of “these functions will all exist for classes of my type”. Interfaces cannot inherit from any class other than other interfaces and cannot be created as an object.</text></li>
                <li className='listColour'><text className="portfolio_text">Abstract class – Similar to interface except that it can have additional functions declared within it. Also, cannot be created as an object and must be inherited from if its functionality is needed.</text></li>
                <li className='listColour'><text className="portfolio_text">Partial Class – Allows the developer to divide the properties, methods and events of a class into multiple source files that are then compiled into a single class when run.</text></li>
                <li className='listColour'><text className="portfolio_text">Sealed Class – A special type of class that cannot be inherited from.</text></li>
                <li className='listColour'><text className="portfolio_text">User Defined Class – A class that is created by the user, can inherit and be inherited form.  This is the most common type of class.</text></li>
            </ul>


            <br></br>

            Within a program, objects interact with one another through the use of inheritance, encapsulation and polymorphism. These three principals work together to form the primary characteristics of object-oriented programming. Inheritance allows you to create a new class that inherits behaviour and properties defined in another class. Through doing this you do not need to re-implement existing code but can instead borrow it from another location in the project. Encapsulation acts as a form of protection for variables within a class object during run time, this can be in the form of hiding data so that it can’t be duplicated or overwritten unintentionally and protect the variable from other classes. The increased flexibility and reusability that this allows when manipulating multiple class types simultaneously is invaluable. Finally, some classes can implement polymorphic behaviour which is when a given class inherits a function, but modifies its behaviour to achieve a different result from the original implementation. 
            <br></br>
            <br></br>
            </text>
            
        </div>
    );
}

export  default content_template;


