import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';

// Sistafam:  https://www.youtube.com/embed/FScfjs8yjkQ?si=9fG1qIdLUnjtxCNi
// UI Project: https://www.youtube.com/embed/GPxwV93Q6n8?si=g-pUCEqXeI5FfyLe
// Experience Design: https://www.youtube.com/embed/juDFK2LI62A?si=16IER2BSIq5Gd3cr
// MazeCo presentation: https://www.youtube.com/embed/J3aMdmDOgdA?si=Be6T5qtaKeRXvfs0





function Content_MTGAGILE() {

    return(
        <div className="contentBox">
            <h1 className="project_Title">Applying AGILE to Hobbies</h1>
            <h2 className="portfolio_heading">Project Overview</h2>
            <text className="portfolio_text"> 
            For many years I have been very consistently applying quite a large chunk of my spare time to my primary hobby which is a card game known as ‘Magic: the Gathering’. In the event that you haven’t heard of the game, it’s the original ‘trading card game’ created in the early 90’s by Richard Garfield. Think of Yu-Gi-Oh, Pokémon or hearthstone, Magic: the Gathering is the grandfather of all of those games, the progenitor of the genre. Given that the game has been around for many years, there’s many ways to play known as “formats” and through one of these nice formats I found a creative outlet were applying an AGILE approach to it helped me maintain this project and improve my overall work flow concerning it. This piece will explore what Magic: the Gathering (MTG) is, what AGILE is and how I mixed the two.
            <br></br>
            </text>
            <h2 className="portfolio_heading">What is Magic: the Gathering</h2>
            <text className="portfolio_text"> 
            As just mentioned, MTG is the oldest trading card game and has been around for 30 years.  The objective of the game is to reduce your opponent’s life total to 0 using a 60-card deck of cards that you have built using the vast pool of over 25000 options. There are many ways to play the game, the one that I will be focusing on here is referred to as ‘draft’. Draft is a very popular format because 3-4 times per year, Wizards of the cost (the company that makes MTG) releases new ‘sets’ of cards for players to play with. These sets are distributed in randomized 15-card booster packs and form the backbone of the draft format. 
            <br></br><br></br>
            To draft, 6-8 players take a booster pack each, open it and select one of the cards within to add to their ‘draft pool’ and then pass their pack to the person on their right. They then receive a pack of 14 cards from the person on their left and once again, take a card and pass it on. This continues until all 15 cards have been taken. The players then continue to repeat this process 2 more times until each player has a draft pool of 45 cards which they then use to build decks of 40 cards and then proceed to play games until one person is declared the overall winner.
            <br></br><br></br>
            The project that I set myself was to create my own custom draft format (here on out referred to as the ‘cube’) using existing cards and keep it updated across the years. This version of draft is commonly referred to as a ‘Cube draft’ and my mine I decided that I wanted to maintain a concise balanced format that was approachable for new players but still fun for veterans. Additionally, my cube has some restrictions as to what cards can be included to make the gameplay more diverse and interesting. 
            <br></br>

            </text>
            <h2 className="portfolio_heading">What  is AGILE</h2>
            <text className="portfolio_text"> 
            AGILE is a project management framework that breaks projects down into several dynamic phases referred to as ‘sprints. Using a phase-based approach without planning phases ahead of time allows teams to dynamically change priorities during the development of a project and ensures that creating immediate value for the customer is always kept as the first priority. Each sprint can be broken down into several steps that should be followed to ensure the smoothest sprint possible and ensure that nothing is left undone for the next phase. The steps within each sprint are: <br></br><br></br>

            <ol>
                <li><text className="portfolio_text">Plan – Create a plan for the sprint and what should be completed </text></li>
                <li><text className="portfolio_text">Design – Create a new design or expand on existing designs to ensure that the implementation is consistent</text> </li>
                <li><text className="portfolio_text">Develop – Actually make the thing that you’ve set out to make</text> </li>
                <li><text className="portfolio_text">Test – Make sure it works</text></li>
                <li><text className="portfolio_text">Deploy – Let others use it</text></li>
                <li><text className="portfolio_text">Review – What did/did not go well?</text></li>
            </ol>

            <br></br>
            The major advantage of this approach is that there’s a large amount of flexibility built into it.  If a feature is not complete by the end of the sprint or if the client decides to change the scope of what they’re asking for, it won’t disrupt the flow of the project and will simply be taken into account for the next sprint. A key limitation of many projects is either time or money and agile is perfectly set up to deliver as much value to the client as possible within the means of the team. This does however mean that it is not uncommon for projects to be missing features on release as the team was unable to complete the development in time.
            <br></br>
            </text>
            <h2 className="portfolio_heading">How I apply AGILE to Magic</h2>
            <text className="portfolio_text"> 
            When it comes to using the AGILE approach to managing my cube, I have been using the sprint design quite by accident for years. In order to keep my cube up to date I will often have to plan what I want from the update, choose the cards that fit the design of the cube, implement the changes, test them and then review those changes. While informal, this approach has allowed me to maintain this cube for approximately 7 years and will continue into the future. The primary way in which learning about AGILE project management modified my cube design approach was that I now consider updates my cube consistently throughout the year and am very careful to apply each step as necessary. The largest phase for my cube management is the cube testing wherein I will find a group of people (usually friends) to draft the cube with and get their feedback.  The feedback that I gather from the testing is then directly used to make the next update. For example, if a card proves to be too strong over several drafts, it will be replaced with something less effective, or if some cards just never get picked then they will be replaced with something stronger. Every change is made with the initial goals in mind of creating an interesting draft experience for both new and experienced players.
            <br></br>
            </text>



            
        </div>
    );
}

export  default Content_MTGAGILE;


