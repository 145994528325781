import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';

// Sistafam:  https://www.youtube.com/embed/FScfjs8yjkQ?si=9fG1qIdLUnjtxCNi
// UI Project: https://www.youtube.com/embed/GPxwV93Q6n8?si=g-pUCEqXeI5FfyLe
// Experience Design: https://www.youtube.com/embed/juDFK2LI62A?si=16IER2BSIq5Gd3cr
// MazeCo presentation: https://www.youtube.com/embed/J3aMdmDOgdA?si=Be6T5qtaKeRXvfs0


import appUIDesign from '.././assets/sistafam_appBreakdown.png';
import sistafamLoginPage from '.././assets/sistafam_LoginPage.JPG';
import sistafamFeedPage from '.././assets/sistafam_Feed.JPG';
import sistafamGroupsPage from '.././assets/sistafam_Groups.JPG';
import sistafamTalkingPage from '.././assets/sistafam_Talking.JPG';
import sistafamUserProfilePage from '.././assets/sistafam_UserProfile.JPG';
import sistafamNewUserProfilePlanning from '.././assets/sistafam_profileUpgrade.jpg';
import sistafamFileHierarchy from '.././assets/sistafam_FileHierarchyHD.jpg';




function content_template() {



    return(
        <div className="contentBox">
            <h1 className="project_Title">Sistafam</h1>
            <h2 className="portfolio_heading">Project Overview</h2>
            <text className="portfolio_text">The SistaFam app was the final year capstone project that I worked on with my team. The goal of the project was for students to get real-world experience working with a client alongside other disciplines to create a working application for a real client. Our client was the start-up business ‘Fit for Life’ and the task we were set was to create the foundation for a social media app called ‘Sistafam’ using the Flutter framework. A previous capstone team the client had worked with developed plans for the visual design and had notes on the broad strokes for the functionality of the app. Our role, was to use these designs and notes to create the app to the best of our abilities. We were able to implement approximately 80% of the features desired and as such the client will be continuing our work with another team during 2023. Sistafam is expected to go live late 2023 or mid 2024 depending on how effectively future teams are able to continue on our work. The features we implemented are account management (login, log out, delete account, make a new account), personal profile, post feed, chats and social group management. 
            <br></br>
            <br></br>
            </text>
            
            <h2 className="portfolio_heading">Video Presentation</h2>
            <Container>
            <div class="ratio ratio-16x9">
                <iframe 
                    src="https://www.youtube.com/embed/FScfjs8yjkQ?si=9fG1qIdLUnjtxCNi" 
                    title="YouTube video" 
                    allowFullScreen
                    className='videoSize'
                    ></iframe>
            </div>
            </Container>
            <h2 className="portfolio_heading">My Contribution</h2>
            <text className="portfolio_text"><br></br><br></br>Team Management<br></br><br></br>
            For the duration of this project, I fulfilled the role of team leader and bore a majority of the responsibility for the project’s development. During my time in this position, I was in charge of organising weekly team meetings, fortnightly client meetings and acting as the spokesperson for the team during our assessed progress meetings with QUT. While we had learnt how to apply AGILE in a previous semester, I opted to use a hybrid of AGILE and waterfall methodologies to plan the project, the combination of which allowed the team to keep the client informed about our current progress while also being able to project an expected timeline. <br></br>
            Along side these responsibilities, I was in charge of ensuring that my team members utilised the appropriate project management tools such as git to share the most recent version of the app. Additionally, the system architecture was planned in large part by myself while working with the client. The diagram below shows the component tree for the final app and a snippet of the report that I wrote to accompany it (My report snippet 1 – app design) has been added at the bottom of this page.
            <br></br><br></br>

                <div>
                    <div className='photoContainer'>
                                <img src={sistafamFileHierarchy}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
                <br></br><br></br>User Profile<br></br><br></br>
                I worked with the client closely to design a suitable personal user profile page within the app that would be suitable for the app. Some requirements for this page were that the user’s information should be displayed as well as any recent posts they’ve created and all of this information should be easily updated. The objective was to take elements from existing popular social media apps such as Facebook, twitter and Instagram and use those to create a user profile that as unique. By using elements that are familiar to the general population from existing apps, the on-boarding process to get people into the app should be smoother. The two images below show the planning slides that I developed and the final product once it was implemented into the app. To accompany this design, I wrote a segment in the report covering the UI design which has been added at the bottom of the page under “My Report Snippet 2 – UI Design”.
                <br></br><br></br>
                <div className='projects_page_GridRow'>
                    <div>
                    <div className='photoContainer'>
                                <img src={sistafamUserProfilePage}className='projectPhoto' alt='Loading'></img>
                    </div>
                    <br></br>
                    </div>
                    <div>
                        <div className='photoContainer'>
                                    <img src={sistafamNewUserProfilePlanning}className='projectPhoto' alt='Loading'></img>
                        </div>
                    </div>
                </div>
                <br></br><br></br>Groups Display<br></br><br></br>
                The groups display proved to be more difficult for me than I had initially anticipated. A large part for this was that I had never had experience using non-relational databases before and needed to build an understanding of how they worked. Once I had learnt enough to utilise this type of database properly, we used Flutter’s integration with firebase to display the correct images, load and also manage the relevant group information. The result – seen below – is that the system pings the database and requests the information for the groups that is then displayed in a grid and can link to the appropriate pages..
                <br></br><br></br>
                <div>
                    <div className='photoContainer'>
                                <img src={sistafamGroupsPage}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
                <br></br><br></br>

            </text>
            <h2 className="portfolio_heading">My Report Snippet 1 - App Design</h2>
            <text className="portfolio_text">
            As for the design of the software architecture itself, we collectively agreed alongside our business partner that Firebase would be used for both the server-side development and integration. Through Firebase we are afforded access to numerous plugins that will streamline the creation of the server-side functions. Given the nature of the platform that is being designed, it’s key to ensure that whatever is created can be expanded for any number of potential users.
            <br></br><br></br>
            we had the choice of what sort of database we wanted to use and ultimately chose to go with a NoSQL database. By doing this we do not need to create and manage a relational database and the return format can be presented in the form of a JSON file for easier file transferring between the client and server. There aren’t many details known about how firebase stores its data using MongoDB other than that it is stored flat and the algorithms for data retrieval and quality assurance are largely unknown. However, for our purposes this worked perfectly as the implementation was straightforward and the outcome was a success. The database structure (Found in Appendix G - Project Architecture) was presented to us via a simple UI that we could interact with on the server side and a collection of direct calls that we could make on the front end.
            <br></br><br></br>
            For security options, firebase has quite a few good options however the primary one is to use Google’s authentication plugins. These plugins include a working set of authentication tokens that can be automatically generated and used for verification as well as the ability to allow users to log in with their google accounts. This functionality is something that we as a team would have been aiming to integrate in the long run however using this feature now allows us to add additional requested features to impress the client. The specific technology appears to be built into firebase itself and is known as ‘FirebaseUi Auth’. 
            <br></br><br></br>
            For the front end, instead of typical HTTP requests, the Firebase Realtime Database uses data synchronisation—every time data changes, any connected device receives that update within milliseconds. Our language of choice is Flutter which runs dart, an object-oriented language built on C# that appears as a hybrid between java and C. This language was requested by the client and was thankfully ideal for our team who have all had prior experience with object-oriented languages.
            <br></br>
            </text>


            <h2 className="portfolio_heading">My Report Snippet 2 - UI Design</h2>
            <text className="portfolio_text">
            The visual design of the app is crucial as it is the element that the users themselves interact with and thus it’s the element that must be tailored directly for them. The previous team understood this well and catered the UI to women through the colour scheme.  By adopting the hot pink as the primary colour for the icons and header they immediately convey that the app is intended for women. Across the app there is a pair of universal elements that are present on every page of the app that facilitate navigation. In the diagram below these three elements can be seen clearly. 
            <br></br>
            <br></br>
            </text>
            <div>
            <div className='photoContainer'>
                            <img src={appUIDesign}className='projectPhoto' alt='Loading'></img>
                        </div>
            </div>
            <text className="portfolio_text">
            <br></br>
            <br></br>
            The header, as the element’s name might suggest, holds the title of whichever section of the app the user has navigated to. Based on the peripheral files provided, an icon could also be presented in the space in the middle as well for a cleaner, more minimalist UI. On the far left of this element sits the button that leads to the app’s settings and core data, at this stage there are no plans to implement the settings feature of the app during this development.
            Within the second section is where a given user will be able to use the primary functions of the app as all the interactive elements will be nested within this window. The contents of the body will vary depending on what the client is currently viewing and will be the only truly dynamic element on the app itself. The importance of and design of the individual sections of the app has been covered in the table below and is a reference for how the team currently expects each page to be set up and what dynamic elements should be present.
            The third element in the universal UI is that of the navigation bar that resides at the foot of each page. This is the element that allows users to navigate to and from the different sections of the app. Upon visiting a section of the app, there should be a small square that becomes highlighted behind the icon to indicate that this is where the user currently is within the app itself. The icons in their current state are certainly not final however and may be updated in the future.
            <br></br>
            <br></br>
            Overall, the team believes that the current UI design, while functional, could use some fixing up especially with regards to the location of some of the icons. An example of this would be moving the user profile access icon to the top right-hand corner of the page and embedding it within element 1. By doing this there will be more space in the navigation bar at the bottom reducing the margin of error for selecting a particular section when clicking a given icon. The result of this should be an overall improved user experience as they will be less frustrated by unnecessary mis-clicks. Another reason to make this change is to both fill in the empty space on the left and thus add to the symmetry of the app but to also mirror what existing apps have done and been in the past. By having the user profile be accessed via the top right we can tap into the user’s unconscious nostalgic side from Facebook had theirs placed in a similar location. Beyond that, the minimalist nature of the universal UI is ideal for the prototype that the team plans on implementing and thus not in need of any direct changes. 
            <br></br>
            <br></br>
            </text>
            <h2 className="portfolio_heading">Additional Photos</h2>
            <div className='projects_page_GridRow'>
            <div>
                <div className='photoContainer'>
                            <img src={sistafamLoginPage}className='projectPhoto' alt='Loading'></img>
                </div>
            </div>
            <div>
                <div className='photoContainer'>
                            <img src={sistafamFeedPage}className='projectPhoto' alt='Loading'></img>
                </div>
            </div>
            <div>
                <div className='photoContainer'>
                            <img src={sistafamTalkingPage}className='projectPhoto' alt='Loading'></img>
                </div>
            </div>

            </div>
        </div>
    );
}

export  default content_template;


