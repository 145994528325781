import './App.css';

import React from "react";
import Header from './Components/header';
import Footer from './Components/footer';
import Layout from './Components/layout';
import Background from './assets/MyPortfolioBackground.png';

function App() {

  return (
    <div 
      className='app' 
      style={{ 
          backgroundImage:`url(${Background})`,
          backgroundSize: 'cover', 
          width: '100%', 
          height: '100%', 
          position: 'fixed' 
          }}>
      <div className='box'>
        <div className='box-container'> 
          <Header />
          <Layout />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
