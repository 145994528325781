import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';

// Sistafam:  https://www.youtube.com/embed/FScfjs8yjkQ?si=9fG1qIdLUnjtxCNi
// UI Project: https://www.youtube.com/embed/GPxwV93Q6n8?si=g-pUCEqXeI5FfyLe
// Experience Design: https://www.youtube.com/embed/juDFK2LI62A?si=16IER2BSIq5Gd3cr
// MazeCo presentation: https://www.youtube.com/embed/J3aMdmDOgdA?si=Be6T5qtaKeRXvfs0


import Zinepage1 from '.././assets/Zine/Zine_redone_1.jpg';
import Zinepage2 from '.././assets/Zine/Zine_redone_2.jpg';
import Zinepage3 from '.././assets/Zine/Zine_redone_3.jpg';
import Zinepage4 from '.././assets/Zine/Zine_redone_4.jpg';
import Zinepage5 from '.././assets/Zine/Zine_redone_5.jpg';
import Zinepage6 from '.././assets/Zine/Zine_redone_6.jpg';
import Zinepage7 from '.././assets/Zine/Zine_redone_7.jpg';
import Zinepage8 from '.././assets/Zine/Zine_redone_8.jpg';
import Zinepage9 from '.././assets/Zine/Zine_redone_9.jpg';
import Zinepage10 from '.././assets/Zine/Zine_redone_10.jpg';
import Zinepage11 from '.././assets/Zine/Zine_redone_11.jpg';
import Zinepage12 from '.././assets/Zine/Zine_redone_12.jpg';
import Zinepage13 from '.././assets/Zine/Zine_redone_13.jpg';
import Zinepage14 from '.././assets/Zine/Zine_redone_14.jpg';
import Zinepage15 from '.././assets/Zine/Zine_redone_15.jpg';
import Zinepage16 from '.././assets/Zine/Zine_redone_16.jpg';




function Content_sonnet18Zine() {

    return(
        <div className="contentBox">
            <h1 className="project_Title">Sonnet 18 Zine</h1>
            <h2 className="portfolio_heading">Project Overview</h2>
            <text className="portfolio_text"> This project is not IT related, but I chose to include it because I am quite proud with how it turned out. The goal was to create an artistic Zine (a small A-5 magazine usually used as an art piece to show off someone’s work) using only letters from fonts of our choosing. Originally, we weren’t given much direction as to how each page was meant to relate, so I chose to use William Shakespeare’s Sonnet 18 to give the zine an ebb and flow that would unify the zine under one commonality. <br></br>
            <br></br>
            </text>

            <div className='zineRow'>
                    <div className='projectPhoto_zineCovers'><img src={Zinepage1}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineLEFT'><img src={Zinepage2}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineRIGHT'><img src={Zinepage3}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineLEFT'><img src={Zinepage4}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineRIGHT'><img src={Zinepage5}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineLEFT'><img src={Zinepage6}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineRIGHT'><img src={Zinepage7}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineLEFT'><img src={Zinepage8}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineRIGHT'><img src={Zinepage9}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineLEFT'><img src={Zinepage10}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineRIGHT'><img src={Zinepage11}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineLEFT'><img src={Zinepage12}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineRIGHT'><img src={Zinepage13}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineLEFT'><img src={Zinepage14}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineRIGHT'><img src={Zinepage15}className='zinePhoto' alt='Loading'></img></div>
                    <div className='projectPhoto_zineCovers'><img src={Zinepage16}className='zinePhoto' alt='Loading'></img></div>
            </div>
            
        </div>
    );
}

export  default Content_sonnet18Zine;


