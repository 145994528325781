import myPhoto from '.././assets/jamesmoss2021.jpg';


function Page_about() {

    return(
        <div className="contentBox">
            <div className="resumeGrid">
                <h1 className="portfolio_heading">Introduction</h1>
                    <div className="aboutRow">
                        <text className='portfolio_text'>I have recently graduated from QUT with a bachelor of IT: Majoring in computer science and I am about to begin my career in IT. I am a quiet, hardworking individual who makes their job a priority and will eagerly engage in company team-building activities.
                        <br></br><br></br>
                        The majority of my experience has been with object-oriented languages and I have recently begun branching out into web development utilising a React framework on a Node.JS platform. To compliment these primary skills, during my studies I learnt how to use and integrate databases, design appropiate software architecture, develop test cases and gained a good understanding of common project management tools (GitHub, Trello, etc).
                        <br></br><br></br>
                        Moving forward, I wish develop my skills in a professional environment, learn how to lead others effectively as well as work alongside them as I believe teamwork is important and gain a deep understanding of how complex systems are structured so that I could one day lead a project.

                        
                        </text>
                        <div className='photoContainer'>
                            <img src={myPhoto}className='myPhoto' alt='Loading'></img>
                        </div>
                        
                    </div>
                <h1 className="portfolio_heading">Technical Skills</h1>
                    <div className="toolboxRow">
                        <text className='portfolio_text'> Java </text>
                        <text className='portfolio_text'> C# </text>
                        <text className='portfolio_text'> .NET </text>
                        <text className='portfolio_text'> Flutter/Dart </text>
                        <text className='portfolio_text'> React </text>
                        <text className='portfolio_text'> MySQL </text>
                        <text className='portfolio_text'> Git </text>
                        <text className='portfolio_text'> Unit Testing </text>
                        <text className='portfolio_text'> Microsoft Office </text>
                        <text className='portfolio_text'> Communication & Public Speaking </text>
                        <text className='portfolio_text'> Agile & Waterfall </text>

                    </div>


            </div>
           

        </div>
    );
}

export  default Page_about;