import React from "react";
import './component_stylesheet.css';


function footer() {

    return(
        <div className="row footer fog">
        </div>
    );
}

export  default footer;