import React from "react";
import './component_stylesheet.css';




function ProjectCard(props) {

    const newPage = props.pagelink;


    return(
        <div>
            <button 
                onClick={() => props.updatepage(newPage)} 
                className="projectCard">
                <div className="cardsplit">
                    <div className="card_topHalf">
                        {props.backgroundImage}
                    </div>
                    <div className="card_bottomHalf">
                        <h1 className="cardTitle">{props.title}</h1>
                        <h2 className="cardSubtitle">{props.subtitle}</h2>
                    </div>
                </div>
            </button>
        </div>
    );
}





export  default ProjectCard;