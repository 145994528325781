import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Container } from 'react-bootstrap';

// Sistafam:  https://www.youtube.com/embed/FScfjs8yjkQ?si=9fG1qIdLUnjtxCNi
// UI Project: https://www.youtube.com/embed/GPxwV93Q6n8?si=g-pUCEqXeI5FfyLe
// Experience Design: https://www.youtube.com/embed/juDFK2LI62A?si=16IER2BSIq5Gd3cr
// MazeCo presentation: https://www.youtube.com/embed/J3aMdmDOgdA?si=Be6T5qtaKeRXvfs0


import mazeGeneralMaze from '.././assets/mazeapp_generalMaze.jpg';
import mazeLargeMaze from '.././assets/mazeapp_largeArtMaze.jpg';
import mazeMaze1 from '.././assets/mazeapp_maze1.jpg';
import mazeMazeAppUI from '.././assets/mazeapp_mazeUI.jpg';
import mazeMazeAppUML from '.././assets/mazeapp_UML.jpg';


function content_MazeApp() {

    return(
        <div className="contentBox">
            <h1 className="project_Title">The MazeCo Maze App</h1>
            <h2 className="portfolio_heading">Project Overview</h2>
            <text className="portfolio_text"> For this university project, we worked in teams of 4 to all create an app for a hypothetical client. The premise was that there is a company (MazeCo) that currently produces the mazes and logic games commonly found in magazines and newspapers and they want to modernize their workflow allowing their workers to be more productive. To achieve this, they wish for a maze editing tool to be made specifically for them and for it to allow their professional maze builders to: <br></br><br></br>
            <ul>
                <li className='listColour'><text className="portfolio_text"> Modify the size of the maze (anywhere from 5x5 to 100x100)</text></li>
                <li className='listColour'><text className="portfolio_text"> Manually change the maze</text></li>
                <li className='listColour'><text className="portfolio_text"> Randomly generate a maze</text></li>
                <li  className='listColour'><text className="portfolio_text"> Have a toggle that would show the solution to the maze so that the maze is solvable</text></li>
                <li className='listColour'><text className="portfolio_text"> Be able to add custom art (e.g., Company logo’s) to the maze and have it not be part of the solution</text></li>
                <li className='listColour'><text className="portfolio_text"> Be able to remove art from the maze</text></li>
                <li className='listColour'><text className="portfolio_text"> Be able to save and load mazes to and from a database</text></li>
                <li className='listColour'><text className="portfolio_text"> Be able to export a maze as an image file</text></li>

            </ul>
            <br></br>
            The list of requirements was clearly defined and as a team we managed to implement every feature for the app. As a result, our final grade for this project was a 7(85%+) across the entire semester including report work and implementation.
            </text>
            
            <Container>
            <div class="ratio ratio-16x9">
                <iframe 
                    src="https://www.youtube.com/embed/J3aMdmDOgdA?si=Be6T5qtaKeRXvfs0" 
                    title="YouTube video" 
                    allowFullScreen
                    className='videoSize'
                    ></iframe>
            </div>
            </Container>

            <h2 className="portfolio_heading">My Contribution</h2>

            <text className="portfolio_text">
            For this project, I was heavily involved in every aspect of the project and had to budget my time carefully to ensure that I could not only complete my work but also be able to assist my team as they needed and keep up to date with my other subjects. My experience with object-oriented languages, databases and simple software design proved instrumental. In order to attempt to divide the work between our team fairly we divided the work into four primary sections:
            <br></br><br></br>


            <text className="portfolio_text"></text>

            <ul>
                <li className='listColour'><text className="portfolio_text">Front end (UI design and implementation)</text></li>
                <li className='listColour'><text className="portfolio_text">Back end (Data structure for the maze and shortest path solution)</text></li>
                <li className='listColour'><text className="portfolio_text">Database Design (The saving and loading of the maze data)</text></li>
                <li className='listColour'><text className="portfolio_text">Image management (adding and removing art from the maze)</text></li>
            </ul>



            <br></br>
            The segment I was tasked with completing initially was the Back-End system that would manage the maze and enable editing, however across the duration of the project I would be involved with every branch of this project. Below is a summary of my contributions and what I did. <br></br><br></br>

            <br></br><br></br>- Team management & Meeting organisation -<br></br><br></br>
            For the duration of this project, I adopted the role of team leader. During meetings with QUT I was the primary spokesperson and organised and ran our weekly team meetings that helped to keep the paperwork up to date. Through doing this I was about to efficiently and effectively allocate the appropriate amount of time and priority for the given tasks within the project to ensure it was done on time. <br></br>

            <br></br><br></br> - Solution Design - <br></br><br></br>
            My task was to create a back-end system that would manage the maze object and facilitate the randomized maze feature, shortest path solution and art placement. I achieve this by setting the maze up as a 2D array of nodes as it allowed us to most easily use X and Y coordinates to find a particular point in the maze. While a more sophisticated tree or graph data structure could have been used, I chose to stick with an array because the number of nodes we were dealing with was a comparatively low number (10000 at most).
            <br></br><br></br>
            For the random maze generation, I used a depth-first approach that starts with the maze entirely populated by walls and then systematically removes them in random directions until the algorithm finds the end node of the maze. At this point, the maze re-traces the path it has just created and makes branches that either lead to dead-ends or loop back to an earlier part of the maze. I used this approach because if the generation went breadth first we determined that there was a chance it would produce an unsolvable maze, by going depth first it was guaranteed that the maze would always be solvable (as seen in the images below). 
             <br></br><br></br>
            To discover the shortest path if it existed, I needed to implement a form of graph traversal. I had the option of implementing Dijkstra’s Algorithm, BFS and A-Star. For this problem, we have access to the location of the start and end nodes and are looking for the shortest path between them, as such the A-star algorithm was the best solution. Additionally, I had already implemented a version of A-start for a previous assignment and of the three it also has a reputation for being the most direct, usually requiring fewer steps on average to find the path than the other two.
            <br></br>
            <br></br><br></br> - Solution Implementation - <br></br><br></br>
            At the end of the project, took the role of lead integration tester and worked closely with individual team members to ensure that the behaviour was as intended. The process we used was to get each feature fully implemented and functional one at a time, making the most basic features the highest priority. In a real-world situation, this would be the equivalent of producing the highest value item during a sprint for the client and then working our way through to the lowest value items.

            </text>
            <h2 className="portfolio_heading">Photos</h2>
            <div className='projects_page_GridRow'>
                <div>
                    <div className='photoContainer'>
                                <img src={mazeGeneralMaze}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
                <div>
                    <div className='photoContainer'>
                                <img src={mazeLargeMaze}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
                <div>
                    <div className='photoContainer'>
                                <img src={mazeMaze1}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
                <div>
                    <div className='photoContainer'>
                                <img src={mazeMazeAppUI}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
                <div>
                    <div className='photoContainer'>
                                <img src={mazeMazeAppUML}className='projectPhoto' alt='Loading'></img>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export  default content_MazeApp;


