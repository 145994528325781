import React from "react";



function content(props) {

    return(
        <div className="content_holder fog">
            {props.p}
        </div>
    );
}

export  default content;